import {
  Stack
} from '@chakra-ui/react'
import CallToActionWithIllustration from './CallToActionWithIllustration'
import FeatureColumns from './FeatureColumns'
import Testimonials from './Testimonials'
import Navbar from './Navbar'
import ContactForm from './ContactForm'
import Portfolio from './Portfolio'

function App() {
  return (
    <Stack align={'center'} spacing={0}
    >

      <Stack align={'center'} spacing={0}
      >
        <Navbar />
        <CallToActionWithIllustration />
        <FeatureColumns />
        <Portfolio />
      </Stack>
      {/*<Testimonials />*/}
      <ContactForm />
    </Stack>

  );
}

export default App;
