import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Icon,
  IconProps,
  Image,
} from '@chakra-ui/react'
import safe from './safe.svg'
import developerHeroImage from './images/undraw_developer_activity_re_39tg.svg'
import tyneBridge from './images/tyne-bridge-black.png'
import Typewriter from 'typewriter-effect'
import { PortfolioSlider } from './Portfolio'

export default function CallToActionWithIllustration () {
  return (
    <Stack
      width={'100%'}
      height={'60vh'}
      textAlign={'center'}
      align={'center'}
      justify={['center', 'center', 'start']}
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
      backgroundColor={'hsla(207,0%,100%,0)'}
      backgroundImage={'radial-gradient(at 55% 95%, hsla(289,100%,49%,0.53) 0px, transparent 50%), radial-gradient(at 91% 91%, hsla(207,100%,49%,1) 0px, transparent 50%),radial-gradient(at 0% 91%, hsla(327,100%,49%,1) 0px, transparent 50%);'}
    >
      <Heading
        fontWeight={600}
        fontSize={{ base: '5xl', sm: '6xl', md: '7xl' }}
        lineHeight={'110%'}>
        <Typewriter
          options={{
            strings: [
              'Websites',
              'Mobile Apps',
              'Software',
              'Social Media',
              'Marketing',
              'Entry Systems',
              'CCTV'],
            autoStart: true,
            loop: true,
          }}
        /> {' '}
        <Text as={'span'} color={'blue.400'}>
          made easy
        </Text>
      </Heading>
      <Text color={'gray.300'} maxW={'3xl'} fontSize={{ base: 'md', sm: 'lg', md: 'xl' }} px={3}>
        We are a software and security start up based in Newcastle. We
        specialise in web development, app development, home security systems,
        door entry systems, and CCTV.
      </Text>
      <Stack spacing={6} direction={'row'}>
        <Button
          rounded={'full'}
          px={6}
          colorScheme={'orange'}
          bg={'blue.400'}
          _hover={{ bg: 'blue.500' }}
          onClick={() => window.location = '/#contact'}
        >
          Get In Touch
        </Button>
      </Stack>
      <Flex w={'full'}>
        {/*<PortfolioSlider />*/}
        <Stack
          width={'100%'}
          position={'absolute'}
          top={'5vh'}
          zIndex={-1}
          width={'max(120vh, 100%)'}
        >
          <Image src={tyneBridge} width={'100%'}
          />
        </Stack>

        {/*<Illustration*/}
        {/*  height={{ sm: '24rem', lg: '28rem' }}*/}
        {/*  mt={{ base: 12, sm: 16 }}*/}
        {/*/>*/}
      </Flex>
    </Stack>
  )
}

export const Illustration = (props) => {
  return (
    <Stack
      width="100%"
      justify={'center'}
      align={'center'}
      {...props}
    >
      <Image src={tyneBridge} width={'500px'}/>
    </Stack>
  )
}
