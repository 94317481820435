import {
  Avatar,
  Box,
  chakra,
  Container,
  Flex,
  Icon, Image, Link,
  SimpleGrid, Stack, HStack, Text,
  useColorModeValue, useTimeout, useInterval,
} from '@chakra-ui/react'
import wbiccWebsiteImage from './images/wbicc.org.uk.jpeg'
import fajrWebsiteImage from './images/fajr.io.jpeg'
import shampanWebsiteImage from './images/theshampan.com.jpeg'
import halalmunchWebsiteImage from './images/halalmunch.com.jpeg'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { FcCommandLine } from 'react-icons/fc'
import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const websites = [
  {
    name: 'Whitley Bay Islamic Cultural Centre',
    link: 'https://wbicc.org.uk',
    content: 'A full website and event management setup for a charity in Whitley Bay.',
    image: wbiccWebsiteImage,
  },
  {
    name: 'Fajr.io',
    link: 'https://fajr.io',
    content: 'We built a website and iOS/Android mobile apps for Fajr. This is an app to help wake up peopl ein the morning.',
    image: fajrWebsiteImage,
  },
  {
    name: 'Shampan Restaurant',
    link: 'https://theshampan.com',
    content: 'Shampan, an indian restaurant based in Whitley Bay.',
    image: shampanWebsiteImage,
  },
  {
    name: 'HalalMunch',
    link: 'https://halalmunch.com',
    content: 'A platform to find halal restaurants, takeaways, and cafes across the UK and US.',
    image: halalmunchWebsiteImage,
  },

]

function TestimonialCard (props) {
  const { name, link, content, image, index } = props
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'900px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      rounded={'xl'}
      p={10}
      justifyContent={'space-between'}
      position={'relative'}
      bg={useColorModeValue('white', 'gray.900')}
    >
      <Stack
        textAlign={'left'}
        justifyContent={{base: 'center', md: 'start'}}
        align={{base: 'center', md: 'start'}}
        // maxWidth={{base: '300px', xl:' 200px'}}
      >
        <chakra.p fontWeight={'bold'} fontSize={20}>
          {name}
        </chakra.p>
        <Link href={link} target={'_blank'}>
          <HStack justify={'center'} align={'center'}>
            <Icon as={FaExternalLinkAlt} w={4} h={4} color={'blue.300'}/>
            <Text color={'blue.300'}><u>{link}</u></Text>
          </HStack>
        </Link>
        <chakra.p
          fontWeight={'medium'}
          fontSize={'15px'}
          pb={4}>
          {content}
        </chakra.p>

      </Stack>
      <Image
        src={image}
        borderRadius={8}
        height={['250px', '300px', '300px', '200px']}
        width={['400px', '400px', '500px', '300px']}
        alignSelf={{base: 'center', md: 'start'}}
        m={{ base: '0 0 35px 0', md: '0 0 0 50px' }}
        boxShadow={'0px 10px 30px #90cdf4'}
      />
    </Flex>
  )
}

export default function Portfolio () {
  return (
    <Flex
      textAlign={'center'}
      pt={10}
      px={5}
      justifyContent={'center'}
      direction={'column'}
      width={'full'}
      overflow={'hidden'}>
      <Box width={{ base: 'full', sm: 'lg', lg: 'xl' }} margin={'auto'}>
        <chakra.h1
          py={5}
          fontSize={48}
          fontWeight={'bold'}
          color={useColorModeValue('gray.700', 'gray.50')}>
          Our Work
        </chakra.h1>
      </Box>
      <SimpleGrid
        columns={{ base: 1, xl: 2 }}
        spacing={'10'}
        mt={8}
        mb={8}
        mx={'auto'}
        px={{base: 0, sm: 5}}
      >
        {websites.map((cardInfo, index) => (
          <TestimonialCard {...cardInfo} index={index}/>
        ))}
      </SimpleGrid>
    </Flex>
  )
}

export function PortfolioSlider () {

  const [index, setIndex] = useState(0)


  useInterval(() => {
    setIndex(i => {
      if (index === websites.length-1) {
        return 0
      } else {
        return i + 1
      }
    })
  }, 5000)

  return (
    <Flex
      justify={'center'}
    >
      <AnimatePresence>
        {websites.map((website, i) => {
          return (
            i === index &&
            <motion.div
              key={i}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 'center' }}
              exit={{ opacity: 0, x: -100 }}
            >
              <Image
                src={website.image}
                borderRadius={8}
                height={['250px', '281px', '300px', '500px']}
                width={['400px', '450px', '500px', '800px']}
                alignSelf={'start'}
                m={{ base: '0 0 35px 0', md: '0 0 0 50px' }}
                boxShadow={'0px 10px 30px #90cdf4'}
              />
            </motion.div>
          )
        })}
      </AnimatePresence>
    </Flex>
  )
}