import { ReactElement } from 'react'
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react'
import { FcCamera, FcLock } from 'react-icons/fc'
import { MdAppShortcut, MdWeb } from 'react-icons/md'
import { TbSocial } from 'react-icons/tb'

const Feature = ({ title, text, icon }) => {
  return (
    <Stack align={['center', 'center']}
           boxShadow={'lg'}
           maxW={'900px'}
           rounded={'xl'}
           p={10}
           justifyContent={'space-between'}
           position={'relative'}
           bg={useColorModeValue('white', 'gray.900')}
    >
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        mb={1}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={'gray.400'} textAlign={'center'}>{text}</Text>
    </Stack>
  )
}

export default function FeatureColumns () {
  return (
    <Box p={6}
    >
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={MdWeb} w={10} h={10} color={'blue.300'}/>}
          title={'Web Design'}
          text={
            'Your success is closely intertwined with your website. Establishing a robust ' +
            'online presence stands as the foremost catalyst for fortifying your brand. ' +
            'A website characterized by sluggishness, dysfunction, or subpar design will ' +
            'deter customers. Our expertise lies in crafting dynamic websites that yield ' +
            'substantial returns on investment.'
          }
        />
        <Feature
          icon={<Icon as={MdAppShortcut} w={10} h={10} color={'blue.300'}/>}
          title={'Mobile Apps'}
          text={
            'In the modern business landscape, a mobile app is crucial for both businesses and brands. ' +
            'With a growing reliance on mobile devices, a well-designed app is essential for staying competitive. ' +
            'Whether in e-commerce, content-sharing platforms, or gaming, we can ' +
            'help design, build, and develop your app.'
          }
        />
        <Feature
          icon={<Icon as={TbSocial} w={10} h={10} color={'blue.300'}/>}
          title={'Digtal Media'}
          text={
            'Your brand stands as your utmost valuable asset, capable of either elevating or hindering your success. ' +
            'Our adept social media specialists possess the knowledge and skill to ' +
            'craft a tailor-made and captivating social media presence, guaranteed ' +
            'to capture attention, even in the most saturated markets.'
          }
        />
        <Feature
          icon={<Icon as={FcLock} w={10} h={10}/>}
          title={'Security'}
          text={
            'Our team is trained in Paxton installations for external single door entry systems, video door entry, and commercial door entry systems.'
          }
        />
        <Feature
          icon={<Icon as={FcCamera} w={10} h={10}/>}
          title={'CCTV'}
          text={
            'We have experience in CCTV installation for home and business. We specialise in Hikvision cameras for home and business with other solutions available also.'
          }
        />
      </SimpleGrid>
    </Box>
  )
}
